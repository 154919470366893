import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import TitleSetter from '../TitleSetter';
import { fetchImagesFromS3 } from '../../utils/s3Config';

function Collections() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const images = await fetchImagesFromS3(50);
        setImageUrls(images.map(image => image.url));
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadImages();
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <TitleSetter title="Geo Gems - Our Collections" />
      <div className={css(styles.container)}>
        <div className={css(styles.gallery)}>
          {imageUrls.map((imageUrl, index) => (
            <div 
              key={index} 
              className={css(styles.imageContainer, 
                index % 3 === 0 && styles.largeTile,
                (index + 1) % 4 === 0 && styles.verticalTile
              )} 
              onClick={() => openModal(imageUrl)}
            >
              <img src={imageUrl} alt={`Collection item ${index + 1}`} className={css(styles.image)} />
            </div>
          ))}
        </div>
      </div>
      {selectedImage && (
        <div className={css(styles.modal)} onClick={closeModal}>
          <img src={selectedImage} alt="Enlarged view" className={css(styles.modalImage)} />
        </div>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: '1rem',
    maxWidth: '1200px',
    margin: '0 auto',
    '@media (max-width: 480px)': {
      padding: '0.5rem',
    },
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
    marginBottom: '1rem',
    color: '#333',
  },
  gallery: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '0.25rem',
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '0.2rem',
    },
    '@media (max-width: 480px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '0.15rem',
    },
  },
  imageContainer: {
    position: 'relative',
    paddingBottom: '100%',
    overflow: 'hidden',
    borderRadius: '3px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    ':hover': {
      transform: 'scale(1.02)',
    },
    '@media (max-width: 480px)': {
      borderRadius: '2px',
    },
  },
  largeTile: {
    gridColumn: 'span 2',
    gridRow: 'span 2',
    '@media (max-width: 768px)': {
      gridColumn: 'span 2',
      gridRow: 'span 2',
    },
    '@media (max-width: 480px)': {
      gridColumn: 'span 2',
      gridRow: 'span 1',
      paddingBottom: '75%',
    },
  },
  verticalTile: {
    gridRow: 'span 2',
    '@media (max-width: 480px)': {
      gridRow: 'span 1',
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    '@media (max-width: 480px)': {
      padding: '0.5rem',
    },
  },
  modalImage: {
    maxWidth: '90%',
    maxHeight: '90%',
    objectFit: 'contain',
    '@media (max-width: 480px)': {
      maxWidth: '98%',
      maxHeight: '98%',
    },
  },
});

export default Collections;