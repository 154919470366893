import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ProductCard from './ProductCard';
import { fetchImagesFromS3 } from '../../utils/s3Config';

function ProductGrid() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const images = await fetchImagesFromS3(10); // Fetch 10 images for the product grid
        const productList = images.map((image, index) => ({
          id: index + 1,
          image: image.url
        }));
        setProducts(productList);
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadImages();
  }, []);

  return (
    <div className={css(styles.productGridContainer)}>
      <div className={css(styles.productGrid)}>
        {products.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  productGridContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  productGrid: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '100%',
    padding: '1rem',
    gap: '1rem',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
});

export default ProductGrid;