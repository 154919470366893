import AWS from 'aws-sdk';

const s3Config = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
};

// Initialize S3 instance
const s3 = new AWS.S3(s3Config);

// Function to fetch multiple images from S3
export const fetchImagesFromS3 = async (limit = 50) => {
  try {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      MaxKeys: limit
    };

    const data = await s3.listObjectsV2(params).promise();
    
    // Generate signed URLs for all images
    const imageUrls = await Promise.all(
      data.Contents.map(async (object) => {
        const signedUrl = await s3.getSignedUrlPromise('getObject', {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: object.Key,
          Expires: 3600, // URL expires in 1 hour
        });
        return {
          url: signedUrl,
          key: object.Key,
          lastModified: object.LastModified
        };
      })
    );

    // Sort images by last modified date (newest first)
    return imageUrls.sort((a, b) => b.lastModified - a.lastModified);
  } catch (error) {
    throw error;
  }
};

export default s3; 